







































































































import { defineComponent, ref, reactive, toRefs, computed, unref } from '@vue/composition-api';
import Vue from 'vue';
import { createToastInterface } from 'vue-toastification';
import { useDomainApi } from '@/module/api/domain';
import DomainDialog from '@/views/app/domains/DomainDialog.vue';
import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import router from '@/router';
import {
  LoadJobModel,
  useLoadJobApi,
  LoadJobType,
  SourceType,
  LoadJobWizardSetupModel
} from '@/module/api/load-job';
import { DomainModel } from '@/module/api/domain';
import { ExportStateName } from '@/module/api/export';

const toast = createToastInterface({ maxToasts: 3 });

const {
  getItems: getDomains,
  items: domains,
  selectItem: selectDomain,
  emptyDomainModel,
  getItem: getDomain,
  isLoading: isDomainLoading
} = useDomainApi();

const {
  createItem: createLoadJob,
  updateItem: updateLoadJob,
  selectedItem: selectedLoadJob,
  selectItem: selectLoadJob,
  getItems: getLoadJobs,
  getItem: getLoadJob,
  emptyLoadJobModel,
  isLoading: isLoadJobLoading,
  setupLoadJob,
  applyWizardSetup
} = useLoadJobApi();

export enum DialogMode {
  VIEW = 'view',
  EDIT = 'edit',
  ADD = 'add'
}

const emptyState = () => {
  return {
    dialog: false,
    currentDomainUuid: '',
    currentJobUuid: '',
    title: 'Reset Domain',
    mode: DialogMode.ADD,
    isPreparing: false,
    havePrepared: false,
    createdLoadJobViewLink: '',
    label: '',
    validForm: false,
    test: '',
    selectedDomain: emptyDomainModel() as DomainModel,
    wizard: {
      step: 1,
      stepsCompleted: 0
    },
    haveFetchedSourceDomain: false,
    showDeveloperButton: false
  };
};

const currentDomain = ref<Partial<DomainModel>>(emptyDomainModel());
const currentLoadJob = ref<Partial<LoadJobModel>>(emptyLoadJobModel());
const domainDialog = ref<any>();

const state = reactive(emptyState());

const selectedDomainCanBeUsed = computed(() => {
  return (
    state.selectedDomain?.mostRecentExtractJob &&
    state.selectedDomain.mostRecentExtractJob?.state == ExportStateName.DONE
  );
});

const selectDomainHint = computed(() => {
  return state.selectedDomain?.mostRecentExtractJob?.objectSummary?.total
    ? state.selectedDomain.mostRecentExtractJob?.objectSummary?.total +
        ' (' +
        state.selectedDomain.region +
        ')' || ''
    : state.selectedDomain?.region
    ? state.selectedDomain.region
    : '';
});

const viewDomain = () => {
  domainDialog.value.view(unref(currentDomain));
};

const reset = () => {
  Object.assign(state, emptyState());
};

const prepareBaseResetJob = async (): Promise<LoadJobModel | undefined> => {
  if (!state.currentJobUuid || state.currentJobUuid == '') {
    return await createLoadJob({
      domainUuid: currentDomain.value?.uuid,
      type: LoadJobType.RESET
    });
  } else {
    return await getLoadJob({ uuid: state.currentJobUuid, forceRefresh: true });
  }
};

const updateLoadJobWizard = async (baseLoadJob: LoadJobModel): Promise<number | undefined> => {
  const updateLoadJobParams: Partial<LoadJobWizardSetupModel> = {
    isComplete: true,
    step: 1
  };
  return await setupLoadJob(baseLoadJob.uuid, updateLoadJobParams);
};

const finalizeLoadJob = async (loadJobUuid: string) => {
  const res = await applyWizardSetup(loadJobUuid);
  if (!res || (res && res < 200) || res > 299) {
    const errMessage = 'Failed to finalize job';
    Vue.$log.error(errMessage);
    toast.error(errMessage);
    return;
  }
  return res;
};

const prepareJob = async () => {
  Vue.$log.debug({ currentLoadJob });
  if (!currentDomain.value?.uuid || currentDomain.value?.uuid === '') {
    const errMessage = 'Not enough information about the current domain to continue';
    Vue.$log.error(errMessage);
    toast.error(errMessage);
    return;
  }

  try {
    state.isPreparing = true;
    const baseLoadJob = await prepareBaseResetJob();
    if (!baseLoadJob || !baseLoadJob.uuid) {
      const errMessage = `Failed to create import job for domain ${currentDomain.value?.domainName}`;
      Vue.$log.error(errMessage);
      toast.error(errMessage);
      return;
    }
    const updateLoadJobResponse = await updateLoadJobWizard(baseLoadJob);
    Vue.$log.debug({ updateLoadJobResponse });

    if (
      !updateLoadJobResponse ||
      (updateLoadJobResponse && updateLoadJobResponse < 200) ||
      updateLoadJobResponse > 299
    ) {
      const errMessage = 'Unable to prepare job setup.';
      Vue.$log.debug(errMessage);
      toast.error(errMessage);
      state.isPreparing = false;
      return;
    }
    const finalizeLoadJobResponse = await finalizeLoadJob(baseLoadJob.uuid);
    Vue.$log.debug('finalized load job response', finalizeLoadJobResponse);
    if (finalizeLoadJobResponse === undefined) {
      const errMessage = 'Unable to finalize job setup.';
      Vue.$log.debug(errMessage);
      toast.error(errMessage);
      state.isPreparing = false;
      return;
    }
    state.createdLoadJobViewLink = `/domain/${currentDomain.value.uuid}/job/import/${baseLoadJob.uuid}`;
    state.havePrepared = true;
    state.wizard.step = 2;
    state.wizard.stepsCompleted = 1;

    toast.success('Prepared job.');
  } catch (err) {
    Vue.$log.error(err);
    toast.error('Failed to prepare import job');
  } finally {
    state.isPreparing = false;
  }
};

const initializeData = async (domainUuid: string, jobUuid?: string) => {
  const domain = await getDomain(domainUuid);
  currentDomain.value = domain;
  await getDomains();
  if (jobUuid) {
    Vue.$log.debug(`Initializing Job with Passed In Job Uuid ${jobUuid}`);
    state.currentJobUuid = jobUuid;
  }
};

const selectableDomains = computed(() => {
  return unref(domains).filter((domain: DomainModel) => {
    return domain.uuid !== currentDomain.value?.uuid;
  });
});

const goToLoadJobDetailPage = () => {
  router.push({ path: state.createdLoadJobViewLink });
};

export default defineComponent({
  name: 'ImportJobResetSetupWizard',
  components: { DomainDialog, DomainWorkspaceToolbar },
  props: ['uuid', 'jobUuid'],
  setup(props) {
    reset();
    state.currentDomainUuid = props.uuid;
    state.currentJobUuid = props.jobUuid;

    const backLink = `/domain/${props.uuid}/workspace`;
    const exportJobsHistoryPageLink = `/domain/${props.uuid}/workspace/export-jobs`;
    const loadJobsHistoryPageLink = `/domain/${props.uuid}/workspace/load-jobs`;

    initializeData(props.uuid, props.jobUuid);
    return {
      ...toRefs(state),
      backLink,
      isDomainLoading,
      isLoadJobLoading,
      reset,
      domains: selectableDomains,
      domainDialog,
      DialogMode,
      selectDomainHint,
      prepareJob,
      currentDomain,
      currentLoadJob,
      exportJobsHistoryPageLink,
      loadJobsHistoryPageLink,
      selectedLoadJob,
      selectedDomainCanBeUsed,
      goToLoadJobDetailPage,
      viewDomain
    };
  }
});
