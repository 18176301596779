var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("domain-workspace-toolbar", {
                attrs: {
                  uuid: _vm.currentDomainUuid,
                  backLink: _vm.backLink,
                  subtitle: "Create a Reset Job"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { loading: _vm.isDomainLoading } },
        [
          _c("v-card-title", [_vm._v("Create a Reset Job")]),
          _c("v-card-subtitle", [
            _c("div", { staticClass: "text-subtitle-1" }, [
              _vm._v(
                " Going through this wizard will setup a reset job that can be run to remove objects from your Domain. "
              )
            ])
          ]),
          _c(
            "v-stepper",
            {
              staticClass: "elevation-0",
              attrs: { vertical: "" },
              model: {
                value: _vm.wizard.step,
                callback: function($$v) {
                  _vm.$set(_vm.wizard, "step", $$v)
                },
                expression: "wizard.step"
              }
            },
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.wizard.step > 1, step: "1" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "d-flex flex-nowrap" },
                            [
                              _c("div", { staticClass: "mt-1" }, [
                                _vm._v("Prepare a Reset Job")
                              ]),
                              _c(
                                "v-icon",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.havePrepared,
                                      expression: "havePrepared"
                                    }
                                  ],
                                  staticClass: "ml-2",
                                  attrs: { color: "success" }
                                },
                                [_vm._v("mdi-check-circle")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mt-2 elevation-2",
                                  attrs: {
                                    border: "top",
                                    "colored-border": "",
                                    type: "warning"
                                  }
                                },
                                [
                                  _vm._v(
                                    " Resetting a domain will clear out any removable items from a domain such as: users, skills, campaigns, and ivr scripts. Make sure that you want to actually clear out the domain before proceeding. "
                                  )
                                ]
                              ),
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mt-2 elevation-2",
                                  attrs: {
                                    border: "top",
                                    "colored-border": "",
                                    type: "info"
                                  }
                                },
                                [
                                  _vm._v(
                                    " By clicking prepare, the reset job will be prepared. You will have the opportunity to review before running the job. "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled:
                                      _vm.isPreparing || _vm.havePrepared
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.prepareJob()
                                    }
                                  }
                                },
                                [_vm._v("Prepare")]
                              ),
                              _vm.isPreparing
                                ? _c("v-progress-linear", {
                                    staticClass: "mt-4",
                                    attrs: {
                                      indeterminate: _vm.isPreparing,
                                      value: 100
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.wizard.step > 2, step: "2" } },
                [_vm._v(" Review ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c("div", { staticClass: "ml-2 my-2" }, [
                              _vm._v(
                                " The reset job has been successfully prepared. Go to the job's detail page to review and run it. "
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _vm.havePrepared
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ml-2",
                                      attrs: {
                                        color: "primary",
                                        disabled: !_vm.havePrepared
                                      },
                                      on: { click: _vm.goToLoadJobDetailPage }
                                    },
                                    [_vm._v("View Job")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("domain-dialog", { ref: "domainDialog" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }